import React from 'react'
import { TitleStroke } from '../../../components/_titles/_header_title_stroke'
import {ButtonSquare} from '../../../components/buttons/_button_square'
import { Picture } from 'react-responsive-picture';
import MadeInMexico from "../../../components/svg/commons/hecho_en_mexico"

import Claim from "../../../components/_claim/claim";


function Banner( props ) {
    if(props.item!== undefined)
    {
        // console.log(props)
        return (
            <>
                <div className="banner__container">
                    <Claim textHtml="!Agua caliente para todos!" />
                    <div className="banner__image">
                    <Picture
                        sources = {[
                            {
                                srcSet: props.item.banner_image.xs.url,
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: props.item.banner_image.sm.url,
                                media: "(max-width: 1023px)",
                            },
                            {
                                srcSet: props.item.banner_image.md.url,
                                media: "(max-width: 1280px)",
                            },
                            {
                                srcSet: props.item.banner_image.lg.url,
                                media: "(max-width: 1919px)",
                            },
                            {
                                srcSet: props.item.banner_image.url,
                                media: "(min-width: 1920px)",
                            },
                            ]}
                    />
                    </div>
                    <div className="banner__header">
                        <div className="banner__title" >
                            <TitleStroke textStroke={props.item.banner_title.text} text={props.item.banner_subtitle.text} colorStroke="#FFFFFF" colorText="#FFFFFF" />
                        </div>

                        <div className="banner__description">
                            <p>{props.item.banner_description.text}</p>
                        </div>

                        <div className="banner__info">
                            <ButtonSquare _className='banner__link--container md' _bg_color='#001f5b' _to={props.item.banner_url} _text={props.item.banner_link_title.text} section={props.item.banner_link_title.text}/>
                            { props.item.made_in_mexico && <MadeInMexico /> }
                        </div>
                    </div>
                    <div className="banner_mask"></div>
                    <ButtonSquare _className='banner__link--container xs' _bg_color='#001f5b' _to={props.item.banner_url} _text={props.item.banner_link_title.text} section={props.item.banner_link_title.text}/>
                </div>
            </>
        )
    }
    else{
        return (<></>)
    }
}

export default Banner
