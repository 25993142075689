import React from 'react'
import {usePrismBlogs} from '../../../hooks/use_prism-blogs'
import { BlogItem,BlogItemTypes  } from '../../../components/_blog/_blog_item';
import HeaderTitle from '../../../components/_titles/_header_title'

function HomeNews() {
    const blogs= usePrismBlogs();

    return (
        <>
            <div className="home__news" >
                <HeaderTitle
                title="BLOG CINSA"
                subtitle="Conoce lo último de nuestras noticias"
                isMainTitle={false}
                index="04"/>
                <BlogItem _className='news__item' blog={blogs.edges[0]} type={BlogItemTypes.TO_RIGHT}></BlogItem>
                <BlogItem _className='news__item' blog={blogs.edges[1]} type={BlogItemTypes.TO_LEFT}></BlogItem>
            </div>
        </>
    )
}

export default HomeNews
