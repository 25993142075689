import React from 'react'
import imgMantenimiento from '../../../../static/assets/images/home/access/icono-mantenimiento.png'
import imgMantenimientoOver from '../../../../static/assets/images/home/access/icono-mantenimiento-over.png'
import imgGarantias from '../../../../static/assets/images/home/access/icono-garantias.png'
import imgGarantiasOver from '../../../../static/assets/images/home/access/icono-garantias-over.png'
import imgDistribuidores from '../../../../static/assets/images/home/access/icono-distribuidores.png'
import imgDistribuidoresOver from '../../../../static/assets/images/home/access/icono-distribuidores-over.png'
import { Link } from 'gatsby'
import { Picture } from 'react-responsive-picture'

const Access = () => {

    function AccessItem(image, imageOver, _to, title, description, _className){
        return(
            <Link to={ _to } className={`access__item ${_className}`}>
                <div className="access__icon">
                    <Picture
                        sources = {[
                        {
                            srcSet: image
                        }
                        ]}
                    />
                    <Picture
                        sources = {[
                        {
                            srcSet: imageOver
                        }
                        ]}
                    />
                </div>
                <div className="access__content">
                    <h2 className='access__title'>{ title }</h2>
                    <p className='access__description'>{ description }</p>
                </div>
            </Link>
        )
    }

    return (
        <div className="access__container">
            { AccessItem(imgMantenimiento, imgMantenimientoOver,'/solicita-ayuda','Solicita Ayuda','Cobertura nacional, la más eficiente.','access_maintenance') }
            { AccessItem(imgGarantias, imgGarantiasOver,'/contactanos','Contáctanos','Protege tu Cinsa. Contáctanos.', 'access_contact') }
            { AccessItem(imgDistribuidores, imgDistribuidoresOver,'/distribuidores','Distribuidores Cinsa','Amplia red nacional de distribuidores.', 'access_dealers') }
        </div>
    )
}

export default Access
