import React from "react";

import Flame from "../../components/svg/commons/flame"
import SvgClaim from "../../components/svg/commons/cinsa_agua_caliente_para_todos"
import "../../styles/_components/_claim/claim.scss";

const Claim = ({ textHtml }) => {
    return (
        <div className="c-claim">
            {/* <Flame fill="#ffffff"/> */}
            <SvgClaim />
            {/* <div className="c-claim__text" dangerouslySetInnerHTML={{ __html: textHtml }} /> */}
        </div>
    )
}

export default Claim;