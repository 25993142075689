import React,{useState} from 'react'
import { usePrismCalentadores } from '../../../hooks/use-prism-calentadores'
import ProdutcsCarousel from './produtcs_carousel'

import HeaderTitle from '../../../components/_titles/_header_title'

function HomeProducts({index="01"}) {
    const calentadoresQuery = usePrismCalentadores()
    const [product, changeProduct] = useState('deposito')

    return (
        <div className="home__produtcs--container">
            <HeaderTitle
                title="MODELOS CINSA"
                subtitle="Conoce nuestros productos"
                isMainTitle={false}
                index={index}
                _className="header__product"/>
            <div className="products__carousel">
                <ProdutcsCarousel calentadoresQuery={calentadoresQuery} _product={product} />
            </div>
        </div>

    )
}

export default HomeProducts
