import React from "react"

const CinsaFlame = (props) => (
  
  <svg
  x={0}
  y={0}
  viewBox="0 0 40 49.6"
  xmlSpace="preserve"
  {...props}
>
  
  <path    
    d="M26.3 49.6S5.5 45.5 1.1 33.7c-5.3-13.8 10.7-20 10.7-20s-10.3 10.2-6 14.9c2.7 2.9 12.7-8.3 14.1-11.9C22 11.2 21.4 0 21.4 0s8.3 11.4 4.7 19.8-15.2 8.8-16.4 14.7c-1 4.8 6.8 11 16.6 15.1z"
    fill={props.fill != null ? props.fill :"#e40244"}
  />
  <path
    d="M31.3 8S40 13.9 40 20.9 27.2 31.6 26.1 37.7c-1.3 6 1 8.4 1 8.4s-5.7-4.3-5.6-9.5c.1-5.3 11.8-12.7 11.8-18.3-.1-6-2-10.3-2-10.3z"
    fill={props.fill != null ? props.fill :"#e40244"}
  />
</svg>
  )


export default CinsaFlame
