import React from 'react';
import { Picture } from 'react-responsive-picture';
import { useStaticQuery, graphql } from "gatsby"

import { usePrismFamily } from '../../../hooks/use_prism-family';
import Claim from "../../../components/_claim/claim"

const BannerAlert = () => {
    const familyQuery = usePrismFamily().edges[0].node.data.body4[0].items[0];

    const alertQuery = useStaticQuery(
        graphql`
            query{
                allPrismicHomepage {
                    edges {
                      node {
                        data {
                          body5 {
                            items {
                              alert_title {
                                html
                                text
                              }
                              alert_description {
                                html
                                text
                              }
                              image_alert {
                                url
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
            }
        `
    );


   const data = alertQuery.allPrismicHomepage.edges[0].node.data.body5[0].items[0]

   const {alert_description, alert_title, image_alert} =  data

    return (
        <div className="banner_alert">
            <div className="banner_alert__img">
                <Picture
                    sources={[
                        {
                            srcSet: image_alert.url
                        }
                    ]}
                />
            </div>
            <div className="banner_alert__container">
                {
                    alert_title && alert_title !== null &&
                    <div className="banner_alert__title" dangerouslySetInnerHTML={{ __html: alert_title.html }} />
                }
               {
                   alert_description && alert_description !== null &&
                   <div className="banner_alert__description" dangerouslySetInnerHTML={{ __html: alert_description.html }} />
               }
            </div>
            <Claim textHtml={familyQuery.family_claim.html} />
        </div>
    )
}
export default BannerAlert