import React from 'react'

function Iframe({_src,_width,_height}) {
    return (
        <>
            <iframe src={_src} width={_width} height={_height} ></iframe>
        </>
    )
}

export default Iframe
