import React from 'react'
import img_maintenance from '../../../../static/assets/images/model_detail/maintenance/cinsa_servicio_mantenimiento.jpg'
import HeaderTitle from '../../../components/_titles/_header_title';
import SvgPhone from '../../../components/svg/maintenance/phone';
import { ButtonSquare } from '../../../components/buttons/_button_square';

const ModelMaintenance = ({_className='', index}) => {
    return (
        <div className={`detail_maintenance ${_className}`}>
            <HeaderTitle
                title="MANTENIMIENTO"
                subtitle="Te brindamos la ayuda que necesitas"
                isMainTitle={false}
                index={index}
                _className="detail_maintenance_title"/>
            <div className="detail_maintenance_wrapper">
                <div className="detail_maintenance_container_text">
                    <h2 className="detail_maintenance_text"><span>¿Necesitas</span> asesoría técnica de un especialista?</h2>
                    <ButtonSquare _to="/solicita-ayuda" _className="detail_maintenance__link xs-hidden sm-hidden" _text="Necesito ayuda" _bg_color="#3780c1"></ButtonSquare>
                </div>
                <div className="detail_maintenance_img">
                    <img src={ img_maintenance } alt="Cinsa Mantenimiento"/>
                </div>
                <div className="detail_maintenance_container">
                    <ButtonSquare _to="/solicita-ayuda" _className="detail_maintenance__link" _text="Necesito ayuda" _bg_color="#3780c1"></ButtonSquare>
                    <div className="detail_maintenance_right">
                        {/* <h2 className="detail_maintenance_text xs-hidden"><span>¿Necesitas un </span>técnico que revise tu calentador?</h2> */}
                        <p className="detail_maintenance_description">
                            <span>¡Llámanos!</span>
                            Comparte con nosotros tu problema y te ayudaremos a resolverlo lo antes posible.
                        </p>
                        <div className="detail_maintenance_contact">
                            <div className="detail_maintenance_phone">
                                <div className="detail_maintenance_icon">
                                    <SvgPhone />
                                </div>
                                 <div>
                                    <p>800 55 <strong>CINSA</strong> (246 72)</p>
                               </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ModelMaintenance