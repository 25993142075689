import React from 'react'
import CinsaFlame from '../../../components/svg/commons/cinsa_flame'

const Storytelling = (props) => {

    const storyQuery = props.data.primary;
    return (
        <div className="storytelling__container">
            <div className="storytelling__text">
                {/* <CinsaFlame /> */}
                <p className="storytelling__text__title"> {storyQuery.storytelling_title.text} </p>
                <p className="storytelling__text__subtitle"> {storyQuery.storytelling_subtitle.text} </p>
                <p className="storytelling__text__description"> {storyQuery.storytelling_description.text} </p>
            </div>
        </div>
    )
}

export default Storytelling
