import { useStaticQuery, graphql } from "gatsby"
export const usePrismFamily = () => {
const prismic = useStaticQuery(
    graphql`
        query{
            allPrismicHomepage{
                edges{
                    node{
                        data{
                            body4{
                                items{
                                    family_img{
                                        url
                                    }
                                    family_title{
                                        html
                                    }
                                    family_description{
                                        html
                                    }
                                    family_claim{
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  );
return prismic.allPrismicHomepage
}