import React, { useState } from "react"

import {graphql, useStaticQuery} from 'gatsby'
import Layout from "../components/layout"
import HomeBaner from '../content/home/banner/home_banner'
import Storytelling from '../content/home/storytelling/storytelling'
import BannerAlert from '../content/home/banner_alert/banner_alert'
import HomeProducts from '../content/home/products/home_products'
import HomeHelp from '../content/home/help/home_help'
import CinsaFamily from '../content/home/family/cinsa_family'
import HomeNews from '../content/home/news/home_news'
import ModelMaintenance from '../content/model_detail/_sections/_model_maintenance'
import Access from '../content/home/footer/access'
// import Head from '../components/head'
import '../styles/pages/home/_home.scss'
import GetBodyItem from '../components/_helpers/_get_body_item'
// import { Alert } from "../components/_alerts/_alert"


const Index = () => {

    const query = useStaticQuery(graphql`
        query {
            allPrismicHomepage {
                edges {
                    node {
                        data {
                            body1 {
                                __typename
                                ... on PrismicHomepageBody1Metas{
                                    slice_type
                                    primary{
                                        title{
                                            text
                                        }
                                        description{
                                            text
                                        }
                                        image_share{
                                            url
                                        }
                                    }
                                }
                                __typename
                                ... on PrismicHomepageBody1Storytelling {
                                    primary {
                                        storytelling_title {
                                            text
                                        }
                                        storytelling_subtitle {
                                            text
                                        }
                                        storytelling_description {
                                            text
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    let metas= {
        title:'Cinsa ',
        description: 'Cinsa ',
        image:''
    }

    let data = query.allPrismicHomepage.edges[0].node;

    let body_info = new GetBodyItem();
    let metas_from_prismic = body_info.get_index_item(data,"PrismicHomepageBody1Metas");
    let storytelling_data = body_info.get_index_item(data,"PrismicHomepageBody1Storytelling");

    // const [alertOpen, setAlertOpen] = useState(true)

    return (
    <Layout>
        {/* <Head
            title={metas.title}
            pathname={`/`}
            description={metas.description}
            image={metas.image}
        />
        {
          alertOpen &&
          <Alert onClickButton={setAlertOpen}/>
        } */}
        <HomeBaner/>
        <Storytelling data={storytelling_data} />
        <BannerAlert/>
        <HomeProducts />
        <HomeHelp/>
        <ModelMaintenance _className="home_maintenance" index="03" />
        <CinsaFamily/>
        <Access/>
        <HomeNews/>
    </Layout>
)}

export default Index
