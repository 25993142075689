import React from 'react';

const SvgPhone = (props) => {
  return (
    <svg viewBox="0 0 196 196" {...props}>
      <g data-name="Layer 2">
        <path
          d="M53 196a51 51 0 01-36-15L3 167a11 11 0 010-16l42-40a11 11 0 0116 0l13 13 50-50-13-13a11 11 0 010-16l40-42a11 11 0 0116 0l14 14a51 51 0 010 72l-92 92a51 51 0 01-36 15zm-35-37l11 11a35 35 0 0049 0l92-92a35 35 0 000-49l-11-11-33 35 14 14 1 1a8 8 0 010 12l-61 61a8 8 0 01-12 0l-15-15zm31-37zM156 14z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default SvgPhone