import React,{useRef,useState, useEffect} from 'react'

import { useCurrenDevice } from "../../../hooks/useCurrentDevice";
import Loadable from "@loadable/component"
import  {Link} from 'gatsby';

import { Picture } from 'react-responsive-picture';
import BlogImageSpot from '../../../components/svg/blog/blog_image_spot'


const AwesomeSwiper = Loadable(() => import("react-awesome-swiper"));

function ProdutcsCarousel( props ) {

  const swiperProduct = useRef(null)
  const [windowWidth, setWindow] = useState(null)
    const [filter, setFilter] = useState(props._product)
    const [items, setItems] = useState([])
    const [widthItems, setWidthItems] = useState()
    const [device] = useCurrenDevice();
    let arrayCalentador = []

    useEffect(() => {
      window.addEventListener("resize", windowSize);
      return () => {
        window.removeEventListener("resize", windowSize);
      };
    }, )

  function windowSize(){
    setWindow(window.innerWidth);
    // console.log('esta es el ancho ', width);
  }

    useEffect(() => {
      setFilter(props._product)
    }, [props._product])

    useEffect(() => {
      if(windowWidth >= 1300){
        setWidthItems(3)
      }
      if(windowWidth >= 768 && windowWidth < 1300 ){
        setWidthItems(3)
      }
      if(windowWidth < 768 ){
        setWidthItems(1)
      }
    }, [windowWidth])

    useEffect(() => {
      props.calentadoresQuery.edges.map(calentador => {
        arrayCalentador.push(calentador)
      })
      arrayCalentador.sort((a, b) => a.node.data.order - b.node.data.order);
      setItems(arrayCalentador)
    }, [filter])


    const configMobile = {
        loop : true,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        speed: 500,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          bulletElement : 'li',
        //   hideOnClick :true,
          clickable :true,
        },
        on: {
          slideChange: function () {
            // console.log(this.activeIndex);
          },
        },
      };
    const configDesktop = {
        loop : false,
        slidesPerView: 5,
        spaceBetween: 10,
        preloadImages: false,
        lazy: true,
        speed: 500,
        on: {
          slideChange: function () {
          },
        },
      };

    let index = 1;

    function getSwiper() {
      return <AwesomeSwiper ref={swiperProduct} config={device == "mobile" ? configMobile : configDesktop}>
            <div className="swiper-pagination products__pagination"></div>
            <div className="swiper-wrapper products__wrapper">
            {
                items.map(calentador => {
                  return(
                          <Link to={`/calentadores/${calentador.node.uid}`} className="swiper-slide product__slide" key={calentador.node.uid}>
                            <div className="products__detail--container">
                                <div className="product__image">
                                  {/* <div className="product__image_circle"></div> */}
                                  <BlogImageSpot />
                                  <Picture
                                    sources = {[
                                    {
                                        srcSet: calentador.node.data.menu_image.mb.url,
                                        media: "(max-width: 768px)",
                                    },
                                    {
                                        srcSet: calentador.node.data.menu_image.lg.url,
                                        media: "(max-width: 1919px)",
                                    },
                                    {
                                        srcSet: calentador.node.data.menu_image.url,
                                        media: "(min-width: 1920px)",
                                    }
                                    ]}
                                  />
                                </div>
                                <div className="product__description">
                                  <div className="product__title">CINSA</div>
                                  <div className="product__name">
                                      <span> {calentador.node.data.name.text} </span>
                                  </div>
                                  <div className="product__claim">

                                  </div>
                                </div>
                            </div>
                          </Link>
                        )
                    })
            }
            </div>
            <div className="swiper-button-prev product__prev"></div>
            <div className="swiper-button-next product__next"></div>
      </AwesomeSwiper>
    }

    function getItem() {
      return <div className="product__single--slide" >
      </div>
    }

    return (
        <>
          {
            getSwiper()
          }
        </>
    )
}

export default ProdutcsCarousel
