import React from 'react'
import headerStyles from '../../styles/_components/_titles/_header_title.module.scss'
import CinsaFlame from '../svg/commons/cinsa_flame'
import flameStyles  from '../../styles/_components/_svg/_cinsa_flame.module.scss'
import { isNullOrUndefined } from 'util'

function HeaderTitle({title, subtitle, isMainTitle = false, _classTitle, _className='', index="01"}) {

    function getClassTitle(){
        if(!isNullOrUndefined(_classTitle)){
            return `${headerStyles[_classTitle]}`;
        }else{
            return null;
        }
    }

    return (
        <div className={`${_className} ${headerStyles.header_titleContainer_header}`}>
            {/* <div className={`o-header_icon_container ${flameStyles.oCinsa__logoflame}`}>
                <CinsaFlame />
            </div> */}
            <span className={`${headerStyles.header_sectionNumber}`}>{index}</span>
            <div className={`${headerStyles.header_containerTitle}`}>
                {isMainTitle ?  <h1 className={`${headerStyles.header_titleTitle} ${getClassTitle()}`}>{title}</h1> :  <h2 className={`${headerStyles.header_titleTitle} ${getClassTitle()}`}>{title}</h2>}
                <p className={headerStyles.header_titleSubtitle}>{subtitle}</p>
            </div>
        </div>
    )
}

export default HeaderTitle
