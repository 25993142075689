import React,{useRef, useState, useEffect} from 'react'
// import { Link } from "gatsby"
import {usePrismBanner} from '../../../hooks/use_prims-banner'
// import BannerSwiper from 'react-awesome-swiper';
import Slider from 'react-slick'
import Banner from './banner'
import BannerVideo from './banner_video'
// import BannerCursor from './banner_client_cursor'

import { TimelineMax, Linear } from 'gsap';


const tl = new TimelineMax({paused: true,repeat:-1});

function HomeBanner() {
    const swiperBanner = useRef(null)
    const bannerSectionRef = useRef(null)
    const timerBarRef = useRef(null)
    const [isDisable, setDisable] = useState(null)
    const [isMouseOver, setMouseOver] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
    const [scrollStart, setScrollStart] = useState();
    const [direction, setDirection] = useState(null);
    const bannerQuery = usePrismBanner().edges[0].node.data.body2;
    const timeForChangeInSeconds = 5;
    let scroll_total = 0;

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots home__dots',
        lazyLoad: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        easing: 'easeInOutCubic',
        slidesToScroll: 1,
        pauseOnHover: true,
        pauseOnFocus: true,
        beforeChange: ()=>{
            tl.seek(0);
        },
        nextArrow: <div className={`banner__prev cursor__change`} ></div>,
        prevArrow: <div className={`banner__next cursor__change`} ></div>
      };

    useEffect(() => {
        if(!bannerSectionRef.current) return;

        bannerSectionRef.current.addEventListener("mousemove", handleMousePosition)
        bannerSectionRef.current.addEventListener("mouseenter", handleMouseEnter)
        bannerSectionRef.current.addEventListener("mouseleave", handleMouseLeave)

        if(window!==undefined)
        {
            const winWidth =window.innerWidth;

            if(winWidth<768){
                setScrollStart('xs');
            }
            else{
                if(winWidth<1024){
                    setScrollStart('md');
                }else{
                    if(winWidth<1366){
                        setScrollStart('sm');
                    }else{
                        if(winWidth<1920){
                            setScrollStart('lg');
                        }
                        else{
                            setScrollStart('xlg');
                        }
                    }
                }
            }
        }
        tl.eventCallback("onRepeat", changeSlider);
        tl.fromTo(timerBarRef.current,timeForChangeInSeconds,{width: '0%'},{width:'100%',ease: Linear.easeNone});
        tl.play();

        return () => tl.kill()

    }, []);
    function changeSlider(){
        swiperBanner.current.slickNext();
    }
    useEffect(()=>{

        switch(scrollStart){
            case 'xs':
                scroll_total = 532;
                break;
            case 'sm':
                scroll_total = 660;
                break;
            case 'md':
                scroll_total = 660;
                break;
            case 'lg':
                scroll_total = 693;
                break;
            case 'xlg':
                scroll_total = 1012;
                break;
        }

        if(scrollStart!==undefined)
        {
            window.addEventListener('scroll', ()=>{
                if(bannerSectionRef.current===null)return;
                let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;

                if(lastScrollY>scroll_total){

                    if(isVisible)
                    {
                        setIsVisible(false);
                    }
                }
                else{
                    setIsVisible(true);
                }
            })

        }
    },[scrollStart]);

    useEffect(() => {
        if(!isVisible)
        {
            tl.pause();
        }
        else{
            tl.resume();
        }
    }, [isVisible]);

    useEffect(() => {
        if(direction === "next") {
            bannerSectionRef.current.style.cursor = `url("/assets/images/home/header/cinsa_icon_next.png") 0 35, auto`;
        } else if(direction === "prev") {
            bannerSectionRef.current.style.cursor = `url("/assets/images/home/header/cinsa_icon_prev.png") 0 35, auto`;
        }
    }, [direction])

    function restartAnimation(){
        // tl.play(0);
    }

    useEffect(() => {

        if(isMouseOver ){

            tl.pause();

        }
        else{
            if(isVisible)
            {
                tl.resume();
            }
        }
    }, [isMouseOver]);

    function handleMousePosition(e) {
        const width = window.innerWidth / 2;
        if(e.clientX > width) {
            setDirection("next")
        } else if(e.clientX < width) {
            setDirection("prev")
        }
    }

    function handleMouseEnter(e) {
        setMouseOver(true);
    }

    function handleMouseLeave(e) {
        setMouseOver(false);

    }


    return (
        <div className="banner__section" ref={bannerSectionRef}>
            <div className="banner__carousel__timer">
                <div className="banner__carousel__timerbar" ref={timerBarRef}></div>
            </div>
            <div className="banner__carousel">
                <div className={`banner__prev cursor__change`} onClick={() => swiperBanner.current.slickPrev()} ></div>
                <div className={`banner__next cursor__change`} onClick={() => swiperBanner.current.slickNext()} ></div>
                {/* <BannerCursor container={bannerSectionRef} /> */}
                <Slider ref={swiperBanner}  {...settings}>
                    {
                        bannerQuery.map(element =>{
                            if(element.__typename === "PrismicHomepageBody2Banner"){
                                return element.items.map((data,_key) => {
                                    return(
                                        <div className={`swiper-slide banner__slide`} key={`banner-${_key}`}>
                                            <Banner  item={data} disable={setDisable} />
                                        </div>
                                    )
                                })
                            }else{
                                if(element.__typename === "PrismicHomepageBody2Bannervideo"){
                                    return element.items.map((data,_key) => {
                                        return(
                                            <div className={`swiper-slide banner__slide`} key={`banner-${_key}`}>
                                                <BannerVideo  item={data} />
                                            </div>
                                        )
                                    })
                                }
                            }
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default HomeBanner