import React from 'react'
import '../../../styles/pages/home/_home.scss'
import HelpCircle from '../../../components/svg/home/help/help_circle'
import Sopot from '../../../components/svg/home/help/spots'
import { ButtonSquare } from '../../../components/buttons/_button_square';
import { Picture } from 'react-responsive-picture';
import HeaderTitle from '../../../components/_titles/_header_title';
import Slider from 'react-slick';

//=============
// I M A G E S
//=============
import img_deposito from '../../../../static/assets/images/home/help/cinsa_tu_calentador_ideal_familia_cinsa.png'
import img_deposito_xs from '../../../../static/assets/images/home/help/cinsa_tu_calentador_ideal_familia_cinsa_S.png'


//=============
// I M A G E S
//=============

const HomeHelp = () => {

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        autoplay: false,
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function getImage(xs, sm, xlg){
        return(
            <div className="home__help--product__image">
                <Picture
                    sources = {[
                    {
                        srcSet: xs,
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: sm,
                        media: "(max-width: 1919px)",
                    },
                    {
                        srcSet: xlg,
                        media: "(min-width: 1920px)",
                    }
                    ]}
                />
            </div>
        )
    }

    return (
        <div className="home__help--container">
            <HeaderTitle
                title="TU CALENTADOR IDEAL"
                subtitle="Te brindamos el calentador que necesitas"
                isMainTitle={false}
                index="02"
                _className="home__help__title"/>
            <div className="home__help--wrapper">
                <div className="home__help--left">
                    <div className="home__help--header">
                        <div className="help__title__text">
                            <span>¿necesitas</span> ayuda para elegir tu calentador?
                        </div>
                        <div className="help__md-visible">
                            <div className="home__help__desc">
                                <span>Nosotros te ayudamos, solo sigue los pasos y te diremos cuál es el calentador ideal para ti. </span>
                            </div>
                            <ButtonSquare _to="/tu-calentador-ideal" _className="home__help__link" _text="Comenzar" _bg_color="#001f5b"></ButtonSquare>
                        </div>
                    </div>
                </div>
                <div className="home__help--right">
                    <div className="home__help--product__container">
                        <Slider { ...settings } >
                            {getImage(img_deposito_xs, img_deposito, img_deposito)}
                            {/* {getImage(img_electrico_xs, img_electrico_sm, img_electrico_xlg)}
                            {getImage(img_depaso_xs, img_depaso_sm, img_depaso_xlg)}
                            {getImage(img_instantaneo_xs, img_instantaneo_sm, img_instantaneo_xlg)} */}
                        </Slider>
                        <div className="home__help--product__bg">
                            <Sopot/>
                        </div>
                    </div>
                    <div className="help__md-hidden">
                        <div className="home__help__desc">
                            <span>Nosotros te ayudamos, solo sigue los pasos y te diremos cuál es el calentador ideal para ti. </span>
                        </div>
                        <ButtonSquare _to="/tu-calentador-ideal" _className="home__help__link" _text="Comenzar" _bg_color="#001f5b"></ButtonSquare>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeHelp
