import * as React from "react"

function SvgSpots(props) {
    return (
        <svg
            id="prefix__Capa_1"
            x={0}
            y={0}
            viewBox="0 0 470 465"
            xmlSpace="preserve"
            {...props}
        >
        <style>{".prefix__st0{fill:#2578bd}"}</style>
        <path
            className="prefix__st0"
            d="M165.07 18.01c2.11.66 4.31.62 6.45 1.11 3.26.75 5.74 2.88 8.77 4.15 1.66.7 3.78 1.1 5.44.43 1.58-.64 2.77-2.04 3.31-3.63.68-2.01.42-4.27-.31-6.34-1.28-3.63-3.63-7.14-6.48-9.8-5.6-5.21-14.64-5.19-19.95-.27-2.5 2.31-3.84 5.94-2.73 9.36.82 2.54 3 4.21 5.5 4.99zM469.54 299.82c-1.37-4.54-5.37-7.34-8.92-6.25-3.56 1.09-5.33 5.67-3.95 10.21 1.37 4.54 5.37 7.34 8.92 6.25 3.55-1.1 5.32-5.67 3.95-10.21zM303.15 175.01c-19.7-28.48-31.81-61.34-53.94-88.37-26.24-32.04-64.26-53.68-107.39-49.62C83.55 42.51 36.26 85.51 15.6 137.23c-43.92 109.93 7.77 243.89 119.09 293.5 56.67 25.26 123.46 37.7 185.93 33.44 35.55-2.42 71.34-13.09 97.86-35.9 21.01-18.07 35.43-44.68 36-72.16.6-28.95-17.69-57.78-37.82-77.87-36.73-36.65-83.39-59.7-113.51-103.23z"
        />
        </svg>
    )
}

export default SvgSpots
