import React from 'react';
import { Picture } from 'react-responsive-picture';

import { usePrismFamily } from '../../../hooks/use_prism-family';
import Flame from "../../../components/svg/commons/flame"
import Claim from "../../../components/_claim/claim"

const CinsaFamily = () =>{
    const familyQuery = usePrismFamily().edges[0].node.data.body4[0].items[0];
    return (
        <div className="home_family">
            <div className="home_family__img">
                <Picture
                    sources={[
                        {
                            srcSet: familyQuery.family_img.url
                        }
                    ]}
                />
            </div>
            <div className="home_family__container">
                <div className="home_family__title" dangerouslySetInnerHTML={{ __html: familyQuery.family_title.html }} />
                <div className="home_family__description" dangerouslySetInnerHTML={{ __html: familyQuery.family_description.html }}/>
            </div>
            <Claim textHtml={familyQuery.family_claim.html}/>
        </div>
    )
}
export default CinsaFamily