import * as React from "react"

function FlameCinsa(props) {
  return (
    <svg
      id="prefix__Capa_1"
      x={0}
      y={0}
      viewBox="0 0 30 37.84"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M7.88 25.17c.75-1.06 2.75-2.34 4.82-3.56 5.45-3.19 7.16-6.08 7.54-10.13C20.68 6.81 16.79 0 16.61 0c.02 7.73-.78 12.96-4.11 16.81-2.53 2.92-5.86 5.6-7.32 5.42-1.18-.14-1.57-2.21-1.47-3.28.36-3.53 4.2-8.49 4.2-8.49-1.2.77-4.3 3.75-4.3 3.75-6.83 7.01-2 13.69-1.39 14.57 4.79 6.9 15.59 9.06 15.59 9.06l-3.08-1.87c-5.5-3.41-9.32-7.31-6.85-10.8z"
      />
      <path
        d="M29.2 12.05c-1.06-2.31-5.03-6.06-5.03-5.88 1.04 5.32.97 7.07.22 8.86-.89 2.14-1.94 3.4-4.3 6.15-2.37 2.74-3.92 4.81-3.77 8.63.1 2.63 3.93 5.5 3.93 5.5s-2.1-5.13.65-8.37c1.96-2.31 4.02-3.63 6.18-5.53 3.17-2.82 3.56-6.19 2.12-9.36z"
      />
    </svg>
  )
}

export default FlameCinsa
