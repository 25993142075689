import React,{useState,useEffect} from 'react'
// import LazyResponsiveImage from 'react-lazy-responsive-image'
import Iframe from '../../../components/_iframe/iframe'

function BannerVideo( props ) {
    // console.log('entro al video', props)
    const resolution = {
            sm:{w: 320,h: 450},
            md:{w: 768,h: 700},
            hd:{w: 1920,h: 800}
    }
    const [videoRes, setRes] = useState(resolution.sm)

    const windowGlobal = typeof window !== 'undefined' && window

    useEffect(() => {
        if(windowGlobal.innerWidth < 768){
            setRes(resolution.sm)
        }
        if(windowGlobal.innerWidth >= 768 && windowGlobal.innerWidth < 1366){
            setRes(resolution.md)
        }
        if(windowGlobal.innerWidth >= 1366){
            setRes(resolution.hd)
        }
        // console.log('resolusion del video ',videoRes)
    }, [windowGlobal.innerWidth])
    // console.log('resolutions',resolution)
    return (
        <>
            <div className="banner__container">
                {/* <LazyResponsiveImage
                    src={props.item.video_preview.hd.url}
                    className="lazy-load-image-background"
                    sources={{                            
                        sm: props.item.video_preview.sd.url,
                        md: props.item.video_preview.mb.url,
                        lg: props.item.video_preview.hd.url,
                    }}
                    breakpoints={{
                        sm: 767,
                        md: 1024,
                        lg: 1366,
                    }}
                    onClick={() => changeToVideo()}
                /> */}
                <div className="banner__embed--video">
                    <Iframe _src={'https://www.youtube.com/embed/mbn2XFP2rBQ'} _width={videoRes.w} _height={videoRes.h} />
                </div>
            </div>
        </>
    )
}

export default BannerVideo
